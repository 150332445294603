import React, {useEffect, useState} from 'react';
import { Config } from '../../config';
import { Toast, Jumbotron, Button, Alert, Navbar, Nav } from 'react-bootstrap';
import axios from 'axios';
import { Sidebar } from './sidebar';
import { Phone } from './phone';
import { Test } from './test';
import { List } from './list';
import { Settings } from './settings';
import { EventEmitter } from 'eventemitter3'; 
import moment from 'moment';
import ReconnectingWebSocket from 'reconnecting-websocket';
const uuid = require('uuid');

const EE = new EventEmitter();

const Device = require('twilio-client').Device;

export const useDidMount = (func) => useEffect(() => { func() }, []);

let userOnline = false;
const setUserOnline = (bool) => {
  userOnline = bool;
};

let connection = null;

function Dashboard(props) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [twilioInitialized, setTwilioInitialized] = useState(false);
  const [online, setOnline] = useState(false);
  const [outbound, setOutbound] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [deviceReady, setDeviceReady] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showTest, setShowTest] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastBody, setToastBody] = useState("");
  const [toastTimestamp, setToastTimestamp] = useState("");
  const [ws, setWs] = useState({});
  const [wsToken, setWsToken] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [warningTimestamp, setWarningTimestamp] = useState('');

  useEffect(() => {
    setUserOnline(online);//function内のfunctionがstateを参照しない？
  }, [online]);

  useEffect(() => {
    if (user) {
      loginToWsServer();
    }
  }, [user]);

  useEffect(() => {
    console.log(token);
    if (token) {
      setupTwilio(token);
    }
  }, [token]);

  useEffect(() => {
    if (wsToken) {
      initTwilio();
      connectToWs();
    }
  }, [wsToken]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (e) => {
        console.log('onmessage', e);
        handleMessage(JSON.parse(e.data));
      };
      ws.onerror = (e) => {
        console.log('onerror', e);
      };
      ws.onclose = () => {
        console.log('socket is closed.');
      };
    }
  }, [ws]);

  useEffect(() => {
    if (showPhone) {
      setShowTest(false);
    }
  }, [showPhone]);

  useEffect(() => {
    onload();
    EE.addListener('outboundCall', startOutboundCall);
    return () => {
      Device.destroy();
      EE.removeListener('outboundCall', startOutboundCall);
    };
  }, []);

  useEffect(() => {
    if (outbound) {
      if (!deviceReady) {
        // error
      } else {
        placeCall();
      }
    }
  }, [outbound]);

  const handleMessage = (e) => {
console.log(e);
    EE.emit(e.event, e.data);
  };

  const loginToWsServer = async () => {
    const session = await props.Auth.currentSession();
    const response = await props.API.post('WebsocketToken', '/websocket_token', {
      headers: {Authorization: session.idToken.jwtToken}, body: {identity: user.attributes.sub}});
    setWsToken(response.token);
    return response;
  };

  const connectToWs = async () => {
    const socket = new ReconnectingWebSocket(`${Config.wsServer}?token=${wsToken}`, null, {
      reconnectInterval: 3000
    });
    socket.onopen = (e) => {
      socket.send(JSON.stringify({action: "sendMessage", data: JSON.stringify({event: 'ws:test', data: 'client connected'})}));
      setWs(socket);
    };
  };

  const handleLogout = async () => {
    try {
      await props.Auth.signOut();
      window.location.reload();
    } catch(e) {
console.log(e);
    }
  };

  const initTwilio = async () => {
    try {
      const session = await props.Auth.currentSession();
      const r = await props.API.get('CapabilityToken', `/capability_token/${user.attributes.sub}`, {
        headers: {Authorization: session.idToken.jwtToken}});
      setToken(r.token);
    } catch(e) {
      console.log(e);
    }
  };

  const handleIncoming = (con) => {
    if (!userOnline || inProgress) {
      openToast({title: '着信あり', body: 'オフラインなので不在扱い'});
      con.ignore();
    } else {
      connection = con;
      setShowPhone(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  const openWarning = (e) => {
    setShowWarning(true);
    setWarningMessage(e.message);
    setWarningTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');
  };

  const handleConWarning = (name) => {
    switch(name) {
    case 'high-rtt':
      openWarning({message: 'ネットワークの遅延が発生'});
      break;
    case 'low-mos':
      openWarning({message: 'ネットワークの遅延が発生'});
      break;
    case 'high-jitter':
      openWarning({message: 'ネットワークのノイズが増加中'});
      break;
    case 'high-packet-loss':
      openWarning({message: '電波障害・ネットワーク障害が発生'});
      break;
    case 'constant-audio-input-level':
      openWarning({message: '無音の時間が続いています'});
      break;
    }
  };

  const setupTwilio = (token) => {
    Device.on('ready', () => {
      setShowAlert(false);
      if (!twilioInitialized) {
        Device.on('connect', (con) => {
          con.on('warning', handleConWarning);
          setInProgress(true);
        });
        Device.on('disconnect', () => {
          setInProgress(false);
          setShowPhone(false);
          setOutbound(false);
        });
        Device.on('offline', (e) => {
          initTwilio();
        });
        Device.on('error', (e) => {
          setShowAlert(true);
          setAlertMessage('エラーが発生しました。画面を再読み込みしてください。');
          if (userOnline || online) {
            initTwilio();
          }
        });
        Device.on('incoming', (con) => {
          handleIncoming(con);
        });
        setTwilioInitialized(true);
      }
      console.log('Twilio is ready');
      setDeviceReady(true);
    });
    Device.setup(token, {
      allowIncomingWhileBusy: false,
      closeProtection: true,
      edge: 'tokyo',
    });
  };

  const onload = async () => {
    try {
      let currentUser = await props.Auth.currentAuthenticatedUser();
      if (currentUser) {
        setUser(currentUser);
      }
    } catch(e) {
      console.log('Please login to proceed');
    }
  };

  const openToast = (e) => {
    setToastTitle(e.title);
    setToastBody(e.body);
    setToastTimestamp(moment().format('YYYY-MM-DD HH:mm:ss'));
    setShowToast(true);
  };

  const startOutboundCall = (e) => {
    if (inProgress) {
      openToast({title: 'エラー', body: '通話中は外線発信できません'});
      return;
    } else {
      setInProgress(true);
      setOutbound(e);
    }
  };

  const placeCall = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    Device.connect({
      to: outbound.to,
      mode: 'outbound',
    });
  };

  if (!user) {
    return (<div />);
  } else {
    return (<div>
      <Navbar bg="light">
        <Navbar.Brand href="/dashboard">{Config.siteTitle}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
          </Nav>
          <Nav>
            <Nav.Link onClick={handleLogout}>
              ログアウト
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Sidebar {...props} 
        EE={EE}
        online={online}
        setOnline={setOnline}
        showTest={showTest}
        setShowTest={setShowTest}
        showSettings={showSettings}
        setShowSettings={setShowSettings}
        callToSuppot={() => {
          if (online) {
            initTwilio();
          }
          setTimeout(() => {
            setOutbound({to: '+818054694667'});
          }, 500);
        }}
      />
      <div className="Main-content">
        <Toast onClose={() => setShowWarning(false)} show={showWarning} animation={false}>
          <Toast.Header>
            <strong className="mr-auto">warning</strong>
            <small>{warningTimestamp}</small>
          </Toast.Header>
          <Toast.Body>{warningMessage}</Toast.Body>
        </Toast>
        <Toast onClose={() => setShowToast(false)} show={showToast} animation={false}>
          <Toast.Header>
            <strong className="mr-auto">{toastTitle}</strong>
            <small>{toastTimestamp}</small>
          </Toast.Header>
          <Toast.Body>{toastBody}</Toast.Body>
        </Toast>
        {showAlert && 
          <Alert variant="danger">{alertMessage}</Alert>
        }
        <Phone {...props} 
          outbound={outbound}
          disconnect={() => {
            setOutbound(false);
            //connection.disconnect();
            Device.disconnectAll();
          }}
        />
        <Phone {...props} 
          EE={EE}
          connection={connection}
          style={{position:'relative'}}
          show={showPhone}
          accept={async () => {
            try {
              let re = await connection.accept();
            } catch (e) {
console.log('accept error', e);
            }
          }}
          disconnect={() => {
            if (connection) {
              if (inProgress) {
                connection.disconnect();
              } else {
                connection.ignore();
                //setConnection(null);
                connection = null;
              }
            }
            setShowPhone(false);
          }}
        />
        {showTest && 
          <Test EE={EE} online={online} Device={Device} {...props} />
        }
        {!showTest && !showSettings &&
          <List EE={EE} online={online} Device={Device} {...props} />
        }
        {showSettings &&
          <Settings EE={EE} online={online} Device={Device} {...props} />
        }
      </div>
    </div>);
  }
}

export{Dashboard};
