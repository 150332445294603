const endPoint = 'https://dcavmzqi57.execute-api.ap-northeast-1.amazonaws.com/v1-godo';
const awsconfig = {
  Auth: {
    identityPoolId: 'ap-northeast-1:87016e80-7317-42f8-a8dc-1447eb267567',
    region: 'ap-northeast-1',
    identityPoolRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_cwyMAR3fL',
    userPoolWebClientId: '611aos00ih3gbjj5pehmp9lalm',
    mandatorySignIn: false,
  },
  API: {
    endpoints: [
      {
        name: 'CapabilityToken',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'TmpUrl',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'List',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'WebsocketToken',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
      {
        name: 'Preferences',
        endpoint: endPoint,
        region: 'ap-northeast-1'
      },
    ]
  }
};

export{awsconfig};
